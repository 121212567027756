<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          {{
            $route.params.id == 0
              ? "Nuevo enlace"
              : "Id enlace: " + $route.params.id
          }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <CInput
                placeholder="Nombre"
                label="Nombre del enlace"
                v-model="form.name"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="link"
                /></template>
              </CInput>
              <CInput
                type="datetime-local"
                placeholder="Inicio"
                label="Fecha de inicio"
                v-model="form.begin_at"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="calendar-check"
                /></template>
              </CInput>
              <CInput
                type="datetime-local"
                placeholder="Hasta"
                label="Fecha fin"
                v-model="form.end_at"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="calendar-times"
                /></template>
              </CInput>
              <CInput
                type="number"
                min="0"
                placeholder="Máximo de usos"
                label="Máximo de usos"
                v-model="form.max_uses"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="exclamation"
                /></template>
              </CInput>
              <CCol col="12" sm="12" lg="12">
                <input type="checkbox" v-model="form.isactive" /> Enlace Activo
              </CCol>
              <CCol col="12" sm="12" lg="12" v-show="alert.show">
                <CAlert :color="alert.color" dismissible>
                  {{ alert.message }}
                </CAlert>
              </CCol>
              <CCardBody v-show="visibleLink">
                <CCardTitle><center>Enlace de pago</center></CCardTitle>
                <br />
                <center>Copia la imagen del código QR para utilizar tu enlace</center><br/>
                <center>
                  <qrcode-vue :value="enlace" :size="size" level="H" />
                </center>
                <br />
                <center><CButton
                    class="js-payment-checkout"
                    color="info"
                    @click="copy"
                  >
                    <font-awesome-icon icon="copy" /> Copiar enlace
                  </CButton></center>
                <br />
                <center>
                  <CButton color="info" @click="generateReport()">
                    Descarga el PDF e imprimelo para incrementar tus ventas
                  </CButton>
                </center>
              </CCardBody>
            </CCol>
            <CCol xs="12" md="6" class="text-right">
              <CButton
                color="primary"
                @click="
                  () => {
                    selector = true;
                  }
                "
              >
                <font-awesome-icon icon="plus" />
                Add group
              </CButton>
              <CDataTable hover striped :items="groups" :fields="fields">
                <template #delete="data">
                  <td>
                    <CButton
                      class="cartAdd"
                      color="danger"
                      size="sm"
                      variant="ghost"
                      @click="deleteLine(data.item)"
                      ><font-awesome-icon icon="trash"/>
                    </CButton>
                  </td>
                </template>
                <template #qty="data">
                  <td>
                    {{ data.item.qty }}
                  </td>
                </template>
                <template #total="data">
                  <td>
                    {{ formatPrice(data.item.total) }}
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <CButton color="primary" @click="save">
            <CSpinner v-if="loading" variant="grow" size="sm" />
            Guardar
          </CButton>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      title="Selector grupos"
      :show.sync="selector"
      :centered="true"
      size="lg"
      color="dark"
    >
      <CDataTable
        hover
        striped
        :items="groupsSelector"
        :fields="fieldsSelector"
        clickable-rows
        @row-clicked="rowSelectorClicked"
      />
    </CModal>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="imprimir"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="990px"

        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <img src="img/enlace.png" style="width:100%; margin-left:75px;" >
            <div style="margin-top: -430px; margin-left: 475px;">
              <qrcode-vue class="py-5" :value="enlace" :size=190 level="H" />
            </div>
            <br><br><br><br><br><br>
            <p style="position: absolute; color:black; top: 380px; left: 462px;">{{ products[0]? products[0].ItemName : '' }}</p>
        </section>
    </vue-html2pdf>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from 'vue-html2pdf';

const groupLinkResource = new Resource("grouplink");
const resourceItems = new Resource("productgroup");

export default {
  name: "LinkGroup",
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  data() {
    return {
      visibleLink: false,
      size: 200,
      enlace: "",
      selector: false,
      usersOpened: null,
      loading: false,
      alert: {
        color: "danger",
        message: "",
        show: false,
      },
      groups: [],
      form: {
        id: "",
        name: "",
        paymentCode: "",
        begin_at: null,
        end_at: null,
        max_uses: 0,
        used: 0,
        total: 0,
        taxbaseamount: 0,
        tax: 0,
        products: [],
      },
      products: [],
      groupsSelector: [],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "delete", label: "" },
      ],
      fieldsSelector: [
        { key: "name", label: "Nombre" },
      ],
    };
  },
  async mounted() {
    await this.loadItems();
    if (this.$route.params.id == 0) {
      this.form.paymentCode = this.uuidv4();
      this.form.isactive = true;
    } else {
      await this.loadLink();
    }
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/customlinksgroup" });
    },
    async loadLink() {
      await groupLinkResource
        .get(this.$route.params.id)
        .then((response) => {
          this.form = response.data.groupLink;
          this.groupsSelector.forEach(group => {
            this.form.groups.forEach(selected => {
              if(group.id === selected.group_id){
                this.groups.push(group);
              }
            });            
          });
          if (this.form.begin_at) {
            this.form.begin_at = this.$moment(
              response.data.payLink.begin_at
            ).format("YYYY-MM-DDTHH:mm");
          }
          if (this.form.end_at) {
            this.form.end_at = this.$moment(
              response.data.payLink.end_at
            ).format("YYYY-MM-DDTHH:mm");
          }
          this.visibleLink = true;
          this.enlace =
            process.env.VUE_APP_FRONTEND_URL+"/#/pages/groups/" + this.form.paymentCode;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async save() {
      this.loading = true;
      this.form.groups = this.groups;
      if (this.$route.params.id == 0) {
        await groupLinkResource
          .store(this.form)
          .then(() => {
            this.alert.show = true;
            this.alert.message = "Se guardó correctamente";
            this.alert.color = "success";
            this.loading = false;
            this.goBack();
          })
          .catch(() => {
            this.alert.message = "Ocurrió un error al guardar";
            this.alert.color = "danger";
            this.loading = false;
          });
      } else {
        await groupLinkResource
          .update(this.$route.params.id, this.form)
          .then(() => {
            this.alert.show = true;
            this.alert.message = "Se guardó correctamente";
            this.alert.color = "success";
            this.loading = false;
            this.goBack();
          })
          .catch(() => {
            this.alert.message = "Ocurrió un error al guardar";
            this.alert.color = "danger";
            this.loading = false;
          });
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    deleteLine(item) {
      this.groups.splice(this.groups.indexOf(item), 1);
    },
    rowSelectorClicked(item) {
      var existItem = this.groups.find(
        (element) => element.id === item.id
      );
      if (existItem) {
        this.selector = false;
      } else {
        this.groups.push(item);
        this.selector = false;
      }
    },
    async loadItems() {
      await resourceItems
        .list()
        .then((response) => {
          this.groupsSelector = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.enlace);
        //alert('Copied');
      } catch ($e) {
        //alert('Cannot copy');
      }
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
  },
};
</script>
